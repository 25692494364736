@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#forkme {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 20;
}

@media only screen and (min-width: 1500px) {
  #forkme {
    display: block;
  }
}

.hover\:transform-scale-103:hover {
  --tw-scale-x: 1.03;
  --tw-scale-y: 1.03;
}

/* tailwind overrides */

.tooltip::before {
  z-index: 99;
}

.tab {
  --tab-padding: 2.25rem;
  --tab-border: 3px;
  --tab-border-color: hsla(var(--bc) / var(--tw-text-opacity, 1));
}

/* tailwind overrides - end */

/* Chessground adaptions */
cg-board {
  border-radius: 4px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

/* prevent gap on the right when stretching board to 100% width */
.cg-wrap coords.ranks {
  top: unset !important;
}
.cg-wrap coords.ranks coord {
  transform: unset !important;
}

.cg-wrap coords.files {
  left: unset !important;
}
.cg-wrap coords.files coord {
  transform: translateX(39%);
}
/* prevent gap on the right when stretching board to 100% width - end */

/* Chessground adaptions - end */
